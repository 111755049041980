@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/timezone/lib/css/blueprint-timezone.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

*:focus {
  outline: none !important;
}

.bp3-dark .bp3-tooltip {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
}

.bp3-dark .bp3-tooltip:not([class*="bp3-intent"]) .bp3-popover-content {
  background: #394b59;
  color: #f5f8fa;
}

.bp3-dark .bp3-tooltip .bp3-popover-arrow:before {
  box-shadow: 1px 1px 6px rgba(16, 22, 26, 0.2);
}

.bp3-dark .bp3-tooltip:not([class*="bp3-intent"]) .bp3-popover-arrow-border {
  fill: #10161a;
}

.bp3-dark .bp3-tooltip:not([class*="bp3-intent"]) .bp3-popover-arrow-fill {
  fill: #394b59;
}

.order-selector-popover.bp3-select-popover .bp3-menu {
  max-width: none;
}